export interface AxiusResponse {
    data: any;
    status: number;
    message: string;
}

export interface ApiTrigger {
    data: any;
    error: any;
    message: string;
}

export interface StoreInterface {
    _store: {
        _auth: {
            user: User;
            token: string;
        },
        _utils: {
            env: 'test' | 'live';
            limit: number;
            loading: {
                primary: string;
                spinning: boolean;
                secondary: string;
            }
        },
        _data: {
            products: Product[];
        };
    },
    _setAuth: (value: { user: User, token: string }) => void;
    _setUser: (value: User) => void;
    _setToken: (value: string) => void;
    _setData: (payload: { key: 'products', value: any }) => void;
    // _setUtils: (payload: { key: 'loading' | 'env', value: any }) => void;
}

export interface User {
    id: string;
    name: string;
    email: string;
    country: string;
    phone_number: string;
    avatar: string;
    avatar_link: string;
    compliance: {
        email: boolean;
        phone_number: boolean;
        document: Document;
    };
    preferences: any;
    created_at: string;
    updated_at: string;
    type: UserTypes;
}

export enum UserTypes {
    USER = 'USER',
    ADMIN = 'ADMIN',
}

export interface Document {
    id: string;
    type: ComplianceDocsType;
    files: {
        back: string;
        front: string;
        selfie: string;
    };
    status: boolean;
    rejection_reason: string;
    rejection_reasons: any;
}

export enum ComplianceDocsType {
    GCARD = 'GCARD',
    DRIVER = 'DRIVER',
    // PASSPORT = 'PASSPORT',
}

export const ComplianceDocsTypeLabels = {
    GCARD: `Ghana Card`,
    DRIVER: `Driver's Licence`,
    // PASSPORT: `Passport`,
}

export interface Filter {
    key: string;
    type: 'text' | 'number' | 'select' | 'date_range';
    value?: string;
    label?: string;
    options?: string[] | { label: string; value: string; }[];
    placeholder?: string;
}

export interface DataMeta {
    limit: number;
    next?: string;
    page: number;
    previous?: string;
    total: number;
}

export enum Folders {
    users = 'users',
    compliance = 'compliance',
}

export enum OtpChannels {
    SMS = 'SMS',
    EMAIL = 'EMAIL',
}

export enum ComplianceChannels {
    email = 'email',
    document = 'document',
    phone_number = 'phone_number',
}

export interface Product {
    icon: string;
    name: string;
    code: string;
    description: string;
    url?: {
        local: string;
        staging: string;
        production: string;
    };
    requires_document?: boolean;
    types?: UserTypes[];
}