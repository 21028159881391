import React from 'react';
import { Button, Form, Input, message, notification, Select, Spin } from 'antd';
import { axius, helpers, images, types } from 'helpers';
import { Alert, LoadingMin, MaterialIcon, Steps, Upload } from 'components';
import _Store from '_Store';

const ComplianceDocument = () => {

    const [form] = Form.useForm();
    const { _store: { _auth: { user } }, _setUser } = React.useContext(_Store);

    const [step, setStep] = React.useState(1);
    const [files, setFiles] = React.useState({ front: '', back: '', selfie: '' });
    const [submitting, setSubmitting] = React.useState(false);

    const submit = (v: any) => {
        if (step === 5) {
            setSubmitting(true);
            axius.put(`users/${user.id}/compliance`, { channel: 'document', document: { type: v.document_type, files, } }).then(res => {
                if (res.status === 200) {
                    _setUser(res.data);
                    notification.success({ message: `We have received your ID`, description: `We will verify and notify you shortly` });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                    notification.error({ ...res });
                }
                setSubmitting(false);
            });
        } else {
            if ((step === 2 && !files.front) || (step === 3 && !files.back) || (step === 4 && !files.selfie)) {
                message.error('Upload file');
            } else {
                setStep(st => (st + 1));
            }
        }
    }

    const doc = user?.compliance?.document;

    return (
        <React.Fragment>
            <div className="flex justify-center">
                <div className="w-11/12 xl:w-3/12">
                    {(!doc?.type || doc?.rejection_reason) ? (
                        <>
                            <div className="millik font-bold mb-2 text-2xl xl:text-3xl">Let's get your verified</div>
                            <div className="text-base text-gray-500 mb-12">We need a litle more information to complete your account verification.</div>
                            {!doc?.status && doc?.rejection_reason && (
                                <Alert color="danger" className="mb-6" icon="security">{doc?.rejection_reason}</Alert>
                            )}
                            <Steps step={step} steps={5} setStep={setStep} />
                            <div className="mb-12" />

                            <Spin spinning={submitting} indicator={<div><LoadingMin /></div>}>
                                <Form form={form} layout="vertical" autoComplete="off" onFinish={submit}>

                                    <div style={{ display: step !== 1 ? 'none' : 'block' }}>
                                        <Form.Item label="Country" rules={helpers.requiredInput}>
                                            <Input size="large" type="text" value="Ghana" prefix={<img src={images.Ghana} alt="Ghana" />} disabled={true} />
                                        </Form.Item>
                                        <Form.Item label="Document Type" name="document_type" rules={helpers.requiredInput}>
                                            <Select size="large" placeholder="Choose one" disabled={submitting}>
                                                {Object.keys(types.ComplianceDocsTypeLabels).map(type => (
                                                    <Select.Option key={type} value={type}>{types.ComplianceDocsTypeLabels[type]}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: step !== 2 ? 'none' : 'block' }}>
                                        <Upload
                                            folder={types.Folders.compliance}
                                            helperText="Upload Front side of ID"
                                            onSuccess={e => setFiles(sf => ({ ...sf, front: e.link }))}
                                        />
                                    </div>

                                    <div style={{ display: step !== 3 ? 'none' : 'block' }}>
                                        <Upload
                                            folder={types.Folders.compliance}
                                            helperText="Upload Back side of ID"
                                            onSuccess={e => setFiles(sf => ({ ...sf, back: e.link }))}
                                        />
                                    </div>

                                    <div style={{ display: step !== 4 ? 'none' : 'block' }}>
                                        <Upload
                                            folder={types.Folders.compliance}
                                            helperText="Upload Selfie with the front of ID"
                                            onSuccess={e => setFiles(sf => ({ ...sf, selfie: e.link }))}
                                        />
                                    </div>

                                    <div style={{ display: step !== 5 ? 'none' : 'block' }} className="border rounded-xl p-4">
                                        <div className="millik mb-6 text-xl">Review the files before submitting</div>
                                        <div className="flex justify-between mb-4 border-b pb-4">
                                            <div className="w-4/12">Document Type</div>
                                            <div className="w-8/12 font-medium">{types.ComplianceDocsTypeLabels[form.getFieldValue('document_type')]}</div>
                                        </div>
                                        <div className="flex justify-between mb-4 border-b pb-4">
                                            <div className="w-4/12">Front side of ID</div>
                                            <div className="w-8/12"><div className="bg-img rounded-lg" style={{ backgroundImage: `url(${files.front})`, width: '100%', }} /></div>
                                        </div>
                                        <div className="flex justify-between mb-4 border-b pb-4">
                                            <div className="w-4/12">Back side of ID</div>
                                            <div className="w-8/12"><div className="bg-img rounded-lg" style={{ backgroundImage: `url(${files.back})`, width: '100%', }} /></div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="w-4/12">Selfie with ID</div>
                                            <div className="w-8/12"><div className="bg-img rounded-lg" style={{ backgroundImage: `url(${files.selfie})`, width: '100%', }} /></div>
                                        </div>
                                    </div>

                                    <div style={{ display: [1, 5].includes(step) ? 'none' : 'block' }} className="mt-8 text-sm text-gray-500">
                                        <div className="flex gap-2 items-center"><MaterialIcon name="check" className="text-2xl" /> Accepted files: JPEG/JPG/PNG (Max size: 250 KB)</div>
                                        <div className="flex gap-2 items-center"><MaterialIcon name="check" className="text-2xl" /> Documents should be in good condition and valid</div>
                                        <div className="flex gap-2 items-center"><MaterialIcon name="check" className="text-2xl" /> Face must be clear and visible</div>
                                    </div>

                                    <div className="flex justify-between gap-6 mt-24">
                                        <div className="w-5/12">
                                            {step > 1 && (
                                                <Button size="large" type="default" block onClick={() => setStep(st => (st - 1))}>Back</Button>
                                            )}
                                        </div>
                                        <div className="w-7/12">
                                            <Button size="large" type="primary" block htmlType="submit">
                                                {step === 5 ? 'Submit for approval' : 'Continue'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Spin>
                        </>
                    ) : (
                        <>
                            {doc?.status && (
                                <Alert color="success" className="mb-6" icon="check_circle">Yay! You passed verification.</Alert>
                            )}
                            {!doc?.status && !doc?.rejection_reason && (
                                <Alert color="warning" className="mb-6" icon="info">Your documents are pending verification.<br />Verification may take up to 72hrs.</Alert>
                            )}
                            <div className="border rounded-xl p-4">
                                <div className="flex justify-between mb-4 border-b pb-4">
                                    <div className="w-4/12">Document Type</div>
                                    <div className="w-8/12 font-medium">{types.ComplianceDocsTypeLabels[doc?.type]}</div>
                                </div>
                                <div className="flex justify-between mb-4 border-b pb-4">
                                    <div className="w-4/12">Front side of ID</div>
                                    <div className="w-8/12">
                                        <div className="bg-img rounded-lg" style={{ backgroundImage: `url(${doc?.files.front})`, width: '100%', }} />
                                    </div>
                                </div>
                                <div className="flex justify-between mb-4 border-b pb-4">
                                    <div className="w-4/12">Back side of ID</div>
                                    <div className="w-8/12">
                                        <div className="bg-img rounded-lg" style={{ backgroundImage: `url(${doc?.files.back})`, width: '100%', }} />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <div className="w-4/12">Selfie with ID</div>
                                    <div className="w-8/12">
                                        <div className="bg-img rounded-lg" style={{ backgroundImage: `url(${doc?.files.selfie})`, width: '100%', }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ComplianceDocument;