import React from 'react';

// ::: app pages
import Account from 'pages/Account';
import Dashboard from 'pages/Dashboard';
import Verification from 'pages/Compliance/Document';
import Verifications from 'pages/Compliance/List';

// ::: auth pages
const Auth = React.lazy(() => import('pages/Auth/_Auth'));


const routes = [
    { path: `/auth/*`, element: Auth, auth: false, },

    { path: `/`, element: Dashboard, auth: true, },
    { path: `/account/*`, element: Account, auth: true, },
    { path: `/verification`, element: Verification, auth: true, },
    { path: `/verifications`, element: Verifications, auth: true, },
    
    { path: `*`, element: Dashboard, auth: true, },
];

export default routes;