import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components';
import { config, helpers } from 'helpers';
import { Modal } from 'antd';
import _Store from '_Store';


const Dashboard = () => {

    const navigate = useNavigate();

    const { _store: { _auth: { token, user }, _data: { products } } } = React.useContext(_Store);


    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="bg-gray-50 py-12 text-center px-6">
                    <div className="millik font-bold text-2xl xl:text-3xl">Welcome to Anchora Technologies</div>
                    <div className="mt-4 text-sm xl:text-base text-gray-500">
                        Welcome to Anchora Technologies, your ultimate destination for comprehensive <br className="hidden xl:inline" />
                        business solutions. Explore our suite of cutting-edge products and services tailored to <br className="hidden xl:inline" />
                        elevate your operations.
                    </div>
                </div>

                <div className="py-12 flex justify-center">
                    <div className="w-11/12 xl:w-9/12 grid grid-cols-1 xl:grid-cols-4 gap-6">
                        {products.map(product => (
                            <div
                                key={product.name}
                                className={`p-6 rounded-xl border- ${product.url ? 'pop hover:bg-[#27ba7c0f] cursor-pointer' : 'opacity-60'}`}
                                onClick={() => {
                                    if (product.url) {
                                        if (product.requires_document && !user.compliance?.document?.status) {
                                            Modal.confirm({
                                                icon: null,
                                                title: `Identity Verification`,
                                                width: 350,
                                                content: `${product.name} requires we verify your identity. We would need more information about you to proceed.`,
                                                okText: `Sure, Proceed`,
                                                cancelText: `Cancel`,
                                                centered: true,
                                                onOk: () => {
                                                    navigate(`verification`);
                                                }
                                            });
                                        } else {
                                            helpers.redirect(`${product.url[config.env]}?sso=${token}`);
                                        }
                                    } else {
                                        return false;
                                        Modal.info({
                                            icon: null,
                                            title: `Request Demo`,
                                            width: 350,
                                            content: `We want to show you how ${product.name} works. Write us at anchoratechs@gmail.com`,
                                            centered: true,
                                        });
                                    }
                                }}
                            >
                                <img src={product.icon} alt={product.name} className="h-[45px] p-1 rounded-full border-2" />
                                <div className="font-semibold text-lg mt-2 mb-1">{product.name}</div>
                                <div className="text-sm">{product.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Dashboard;