import React from 'react';
import _Store from '_Store';
import { axius, datatables, helpers, types } from 'helpers';
import { PageTitle, Table } from 'components';

const Details = React.lazy(() => import('./Details'));

const Compliance = () => {

    const { _store: { _utils: { limit } } } = React.useContext(_Store);

    const [row, setRow] = React.useState<types.User>();
    const [data, setData] = React.useState<types.User[]>([]);
    const [total, setTotal] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line 
    }, []);

    const getData = (step = 0) => {
        setLoading(true);
        axius.get('users/compliance', { limit: `${step}-${limit}`, }).then((res) => {
            if (res.status === 200) {
                setData(res.data.data);
                setTotal(res.data.meta.total);
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className="flex justify-center">
                <div className="w-11/12 xl:w-9/12">
                    <div className="flex items-center justify-between mb-6">
                        <PageTitle title="Verifications" />
                    </div>
                    <Table
                        loading={loading}
                        heading={[...datatables.Compliance.heading]}
                        data={datatables.Compliance.data(data)}
                        onClick={e => setRow(data.find(d => d.id === e.id))}
                        pagination={{
                            total,
                            perPage: limit,
                            navigate: getData,
                        }}
                    />
                </div>
            </div>

            <Details
                open={!!row?.type}
                user={row}
                onClose={() => setRow(null)}
                onSuccess={e => {
                    const copyData = helpers.copyObject(data) as types.User[];
                    const i = copyData.indexOf(copyData.find((row) => row.id === e.id));
                    copyData[i] = e;
                    setData(copyData);
                }}
            />
        </React.Fragment>
    );
}

export default Compliance;