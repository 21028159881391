import React from 'react';

const AccountProfile = React.lazy(() => import('./Profile'));
const AccountPassword = React.lazy(() => import('./Password'));

const Account = () => {

    return (
        <React.Fragment>
            <div className="py-12- flex justify-center">
                <div className="w-11/12 xl:w-5/12">
                    <div className="text-center mb-8">
                        <div className="millik text-2xl">My Account</div>
                        <div className="text-sm text-gray-500 mt-1">Manage basic info, such as your name and photo, that you see in your profile.</div>
                    </div>

                    <AccountProfile />
                    <div className="mt-6" />
                    <AccountPassword />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Account;