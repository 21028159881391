import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import { config } from 'helpers';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { StoreProvider } from './_Store';

import App from './App';
import './index.less';
import './assets/scss/_scss.scss';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#09cc94',
    },
    secondary: {
      main: '#045e60',
    },
  },
});

const antdTheme = {
  token: {
    colorPrimary: '#09cc94',
    colorSecondary: '#045e60',
    borderRadius: 8,
    fontFamily: '"Libre Franklin", sans-serif',
  },
};


config.sentry_dns && Sentry.init({
  dsn: config.sentry_dns,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['https://sso.anchoratechs.com/'],
  environment: config.env,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <ConfigProvider theme={antdTheme}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </ConfigProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
