import { Status } from 'components';
import { helpers, types } from 'helpers';

export const Users = {
    heading: ['Name', 'Description', 'Credentials', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        return {
            id: row.id,
            name: {
                width: 20,
                value: row.name,
            },
            credentials: (
                <>

                </>
            ),
            status: <Status status={0} label={row ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.created_at),
            },
        };
    })
}

export const Compliance = {
    heading: ['Name', 'E-mail', 'Phone Number', 'Document', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        const doc = row.compliance?.document;
        return {
            id: row.id,
            name: {
                width: 30,
                value: row.name,
            },
            email: {
                width: 15,
                value: row.email,
            },
            phone: {
                width: 15,
                value: helpers.format.phoneNumber(row.phone_number),
            },
            document: {
                width: 12,
                value: types.ComplianceDocsTypeLabels[doc?.type] ?? 'N/A',
            },
            status: <Status status={doc?.rejection_reason ? 2 : (doc?.status ? 1 : 0)} label={doc?.rejection_reason ? 'Rejected' : doc?.status ? 'Verified' : 'Pending'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.created_at),
            },
        };
    })
}